<template>
  <div name="dossier-faces" class="dossier-faces cols flex-multi">
    <attachment :editable="editable" :externalItem="item" v-for="(item, idx) in items" :key="'dossier-face-attachment-' + idx" @delete="deleteItem(item)" />

    <template v-if="editable">
      <attachment
        :editable="editable"
        :local="true"
        :externalItem="item"
        v-for="(item, idx) in fakeItems"
        :key="'fake-item-' + idx"
        @delete="deleteItem(item)"
      />
    </template>

    <el-button
      name="add-btn"
      ref="buttonAdd"
      size="large"
      @click="attachHandle"
      icon="el-icon-plus"
      class="attachment--button-add"
      :loading="itemLoading"
      v-if="editable"
    >
    </el-button>

    <input name="file" type="file" ref="fileInput" @change="addFile" class="hidden" />

    <el-dialog name="attachment-dialog" ref="attachmentDialog" :title="$tfo('file')" :visible.sync="attachment.enabled" class="attachment-dialog--container">
      <el-form-item :label="$tf('name')" prop="name" class="item-overflow-ellipsis">
        {{ attachment.item.name }}
      </el-form-item>
      <el-form-item :label="$tf('comment')" prop="comment">
        <el-input name="comment" v-model="attachment.item.comment"></el-input>
      </el-form-item>
      <el-button name="save-btn" type="primary" slot="footer" @click="saveDialogHandler">{{ $tfo('save') }}</el-button>
    </el-dialog>
  </div>
</template>

<script>
import Attachment from './attachment';
import _ from '../../../apps/common/lodash';
const DefaultAttachment = {
  enabled: false,
  item: { name: '', comment: '', file: null, size: 0 }
};
export default {
  name: 'dossier-attachments',
  components: {
    Attachment
  },
  props: ['id', 'limit', 'editable'],
  data: function () {
    return {
      itemLoading: false,
      items: [],
      fakeItems: [],
      attachment: _.cloneDeep(DefaultAttachment)
    };
  },
  watch: {
    id: function (v, p) {
      this.items = [];
      v && (this.fakeItems.length ? this.migrateItems() : this.loadItems()).then(() => this.afterCreate());
    },
    editable: function (v, p) {}
  },
  mounted() {
    this.loadItems();
  },
  beforeDestroy() {},
  methods: {
    attachHandle(e) {
      this.$refs.fileInput.click(e);
    },
    afterCreate() {
      this.$emit('created');
    },
    migrateItems() {
      const promises = this.fakeItems.map((v) =>
        this.loadFile(v).then((r) => {
          this.fakeItems = this.fakeItems.filter((i) => i !== v);
          return true;
        })
      );
      return Promise.all(promises).then((v) => console.log('[attachments:migrate] Finished'));
    },
    loadItems() {
      if (!this.id) return;
      this.selected = [];
      return this.$store
        .dispatch(this.$store.state.dossier_attachments.Action.Get, {
          filter: {
            dossier: this.id,
            limit: this.limit || 1e3
          }
        })
        .then((v) => {
          this.items = v.results;
          this.$emit('loaded', this.items);
        });
    },
    deleteItem(item) {
      this.$confirm(this.$tf('you | want | delete | file') + '?', this.$tf('common.warning'), {
        confirmButtonText: this.$tf('yes'),
        cancelButtonText: this.$tf('cancel'),
        type: 'warning'
      })
        .then(() => {
          if (this.fakeItems.indexOf(item) > -1) {
            this.fakeItems.splice(this.fakeItems.indexOf(item), 1);
          } else {
            this.$store.dispatch(this.$store.state.dossier_attachments.Action.Delete, item).then((v) => this.loadItems());
          }
        })
        .catch(() => {});
    },
    addFile(e) {
      const file = e.target.files[0];
      this.showAttachmentDialog(file);
      e.target.value = null;
    },
    showAttachmentDialog(file) {
      this.attachment.item = { file, name: file.name, size: file.size, comment: '' };
      this.attachment.enabled = true;
    },
    saveDialogHandler() {
      this.attachment.enabled = false;
      // this.$refs.attachmentDialog.hide()
      this.addFileInternal(this.attachment.item);
    },
    addFileInternal(item) {
      if (!this.id) {
        this.fakeItems.push(item);
        return Promise.resolve(true);
      } else {
        return this.loadFile(item);
      }
    },
    loadFile(item) {
      let formData = new FormData();
      formData.append('name', item.name);
      formData.append('size', item.size);
      formData.append('comment', item.comment);
      formData.append('file', item.file);
      formData.append('dossier', this.id);

      this.itemLoading = true;
      return this.$store
        .dispatch('requestApi', { model: 'dossier-attachments', data: formData, method: 'post', timeout: 6e4 })
        .then((v) => {
          this.loadItems();
          this.itemLoading = false;
          return Promise.resolve(true);
        })
        .catch((e) => {
          console.error(e);
          this.itemLoading = false;
          this.$notify({
            title: this.$tf(['common.upload', 'common.file,,1', 'common.failed,,1']) + '. ',
            duration: 0,
            message: this.$createElement('message-box', { props: { e: e } })
          });
          return Promise.resolve(true);
        });
    }
  }
};
</script>

<style lang="stylus">
.attachment-dialog--container {
  .el-dialog__footer {
    text-align: center;
  }

  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .el-dialog {
    width: 80%;
    max-width 600px;
  }
}

.dossier-faces {
  width: 100%;
}

.attachment--button-add {
  border-radius: 0.25rem !important;
  display: inline-flex;
  margin: 0 1rem 1rem 0;
  width: 160px;
  height: 80px;

  i {
    margin: auto;
    font-size: 3rem;
  }
}

.hidden {
  display: none;
}
</style>
