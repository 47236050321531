<template>
  <div class="attachment--container mar-right--1 mar-bottom--1" :style="{ opacity: local ? 0.5 : 1 }">
    <a :href="item.file" target="_blank">
      <span style="overflow-wrap: break-word; word-break: break-all">
        <i class="fa fa-file pad-right--0-5" /> {{ $filters.shortString(item.comment || item.name, 40) }},
        {{ getFileSize() | bytesToKb }}
      </span>
    </a>

    <div class="attachment--overlay abs-full cols cols--center" v-if="editable">
      <a :href="item.file" target="_blank" v-if="!local">
        <i class="attachment--icon-button flex-cell--static fa fa-file"></i>
      </a>
      <i class="attachment--icon-button flex-cell--static el-icon-delete" @click="$emit('delete')"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'attachment',
  props: ['editable', 'id', 'externalItem', 'local'],
  data: function () {
    return {
      error: false,
      item: { name: '', comment: '', file: '', size: 0 }
    };
  },
  watch: {
    id: function (v, p) {
      this.load();
    },
    externalItem: function (v, p) {
      this.item = v;
    }
  },
  mounted() {
    if (this.externalItem) {
      this.item = this.externalItem;
    } else {
      this.load();
    }
  },
  methods: {
    getFileSize() {
      return this.item.size || (typeof this.item.file === 'object' ? this.item.file.size : 0) || 0;
    },
    load() {
      let name = 'dossier-attachments/' + this.id,
        promiseName = 'promise/' + name,
        cachedItem = this.$cache.get(name),
        promise = this.$cache.get(promiseName),
        hasPromise = !!promise;

      this.item = { id: null, source_photo: '' };
      this.error = null;

      if (!this.id) return;

      if (cachedItem) {
        this.item = this.$cache.get(name);
      } else {
        promise = promise || this.$store.dispatch(this.$store.state.dossier_attachments.Action.Get, { id: this.id });
        promise
          .then((v) => {
            if (this.id !== v.id) return;
            this.item = v;
            this.$cache.set(name, v);
          })
          .catch((e) => {
            this.error = true;
          });
        !hasPromise && this.$cache.set(promiseName, promise);
      }
    }
  }
};
</script>

<style>
.attachment--id {
  position: absolute;
  line-height: 1.6rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.8rem;
  right: 0;
  top: 0;
  color: #fff;
  background-color: #000;
}

.attachment--container {
  position: relative;
  display: inline-flex;
  padding: 0;
  width: 160px;
  height: 80px;
}

.abs-full {
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.attachment--icon {
  color: #ccc;
  font-size: 2rem;
  padding: 0.5rem;
}

.attachment--icon-button {
  cursor: pointer;
  color: #ccc;
  font-size: 2rem;
  padding: 0.5rem;
}

.attachment--icon-button:hover {
  color: #fff;
}

.attachment--icon-button: {
  color: #fff;
}

.attachment--error {
  border: 1px solid #ccc;
  opacity: 1;
}

.attachment--overlay {
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  opacity: 0;
}

.attachment--overlay:hover {
  transition: opacity 0.2s;
  opacity: 1;
}
</style>
