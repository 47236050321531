<template>
  <page-layout>
    <span slot="header-name">{{ $tf(create ? 'create | dossier,,1' : 'edit | dossier,,1') }}</span>
    <div v-if="create" slot="header-detail">
      <span class="text-tetriary">{{ $tf('do_you_have_many_dossiers') }}</span>
      <span class="text-tetriary"> {{ $tf('try') }} </span>
      <router-link :to="{ path: '/dossiers/batch/' }">{{ $tt('batch_dossier_upload__after_try__') }}</router-link>
    </div>
    <common-tabs slot="header-actions" v-if="metaTabs" v-model="tab" :items="metaTabs" class="dossier-header-tabs"></common-tabs>

    <div slot="content">
      <el-form
        v-loading="loading"
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        :disabled="!edit"
        ref="form"
        :model="item"
        :rules="rules"
        :name="$options.name"
      >
        <el-form-item v-show="generalTab" :label="$tf('common.photos')">
          <objects-inline
            v-for="(obj, index) in enabledObjects"
            ref="dossierObjects"
            :showId="true"
            :editable="edit"
            :id="item.id"
            :show-label="true"
            :object="obj"
            :key="obj"
            :index="index"
            @created="afterCreate"
            @loaded="loadObjectHandler"
          ></objects-inline>
        </el-form-item>

        <el-form-item v-show="generalTab" :label="$tf('files')">
          <dossier-attachments
            ref="dossierAttachments"
            :editable="!$hasNoPermission('ffsecurity.add_dossier') && edit"
            :id="item.id"
            @loaded="loadAttachments"
          ></dossier-attachments>
        </el-form-item>

        <el-form-item v-if="generalTab && item.id" :label="$tf('common.id')">
          <span name="id">{{ item.id }}</span>
          <a v-if="item.id" :href="getFilterPath({ matched_dossier: item.id })" target="_blank">
            <i class="mar-left--1 fa fa-filter"></i>
            {{ $tf('common.events') }}
          </a>
          <template>
            <a name="search-events-btn" :href="getSearchPath({ targetType: 'events' })" target="_blank">
              <i class="mar-left--1 el-icon-search"></i> {{ $tf('common.events') }}
            </a>
            <a name="search-dossiers-btn" :href="getSearchPath({ targetType: 'dossiers' })" target="_blank">
              <i class="mar-left--1 el-icon-search"></i> {{ $tf('common.dossiers') }}
            </a>
          </template>
        </el-form-item>

        <el-form v-if="generalTab && !create && $store.getters.puppeteer">
          <el-form-item :label="$tf('external,,1 | systems')" :disabled="false">
            <dossier-monitoring-item
              v-if="item.id"
              ref="dossierMonitoring"
              :dossier="item.id"
              @add="isSenderVisible = true"
              @remove="removeFromMonitoring"
            ></dossier-monitoring-item>
            <!--<el-button @click="isSenderVisible = true" :disabled="false">{{$tf('add_to_monitoring')}}</el-button>
              <el-button @click="removeFromMonitoring">{{$tf('remove_from_monitoring')}}</el-button><br/>-->
            <el-button class="mar-v-1" @click="externalSearch">{{ $tf('external | search') }}</el-button>
          </el-form-item>
        </el-form>

        <el-form-item v-if="generalTab" :label="$tf('common.name')" prop="name">
          <el-input name="name" v-model="item.name"></el-input>
        </el-form-item>

        <!-- meta fields -->
        <meta-field :meta="meta" :edit="edit" :item="item.meta" v-for="(meta, metaKey) in displayedMetaFields" :key="'meta-field' + metaKey"></meta-field>

        <el-form-item v-if="generalTab" :label="$tf('common.comment')" prop="comment">
          <el-input name="comment" v-model="item.comment"></el-input>
        </el-form-item>

        <el-form-item v-if="generalTab && carsEnabled" :label="$tf('license_plate_number')" prop="comment">
          <el-input name="comment" v-model="item.car_license_plate_number"></el-input>
        </el-form-item>

        <el-form-item v-if="generalTab" :label="$tfo('common.dossier_lists')" prop="dossier_lists">
          <el-select name="dossier-lists" v-model="item.dossier_lists" :multiple="true" :placeholder="$tf('common.select')">
            <el-option
              v-for="listItem in $store.getters.dossierListsInDossier"
              :key="listItem.id"
              :value="listItem.id"
              :disabled="listItem.disabled"
              :label="$filters.shortString(listItem.name)"
            >
              <dossier-list-option :item="listItem"></dossier-list-option>
            </el-option>
          </el-select>

          <span class="compact-buttons">
            <el-button size="mini" circle icon="el-icon-refresh" @click="loadDossierLists" />
            <el-button size="mini" circle icon="el-icon-plus" @click="addDossierList" />
          </span>
        </el-form-item>

        <el-form-item v-if="generalTab && item.created_date" :label="$tf('common.created')">
          <span name="created-date">{{ item.created_date | isoStringToDate | formatDateTime }}</span>
        </el-form-item>

        <el-form-item v-if="generalTab && item.modified_date" :label="$tf('common.was_updated,, 1')">
          <span name="modified-date">{{ item.modified_date | isoStringToDate | formatDateTime }}</span>
        </el-form-item>

        <el-form-item v-if="generalTab" prop="active">
          <el-checkbox name="active" v-model="item.active">{{ $tf('common.active,,2') }}</el-checkbox>
        </el-form-item>
      </el-form>

      <div v-if="puppeteerDailyTab">
        <page-daily-events :dossier="item.id" :header="false"></page-daily-events>
      </div>

      <!-- bottom buttons -->
      <el-form v-if="puppeteerDailyTab">
        <div style="clear: both"></div>
        <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
      </el-form>
      <el-form v-else>
        <el-form-item>
          <el-button
            v-if="edit"
            name="save-btn"
            type="primary"
            @click="saveHandler"
            :disabled="create ? $hasNoPermission('ffsecurity.add_dossier') : $hasNoPermission('ffsecurity.change_dossier')"
            >{{ $tf('common.save') }}</el-button
          >

          <confirm-button
            target="dossier"
            type="danger"
            @confirm="deleteHandler"
            :disabled="$hasNoPermission('ffsecurity.delete_dossier')"
            v-if="!create && !edit"
          >
          </confirm-button>

          <el-button
            v-if="!edit"
            :disabled="create ? $hasNoPermission('ffsecurity.add_dossier') : $hasNoPermission('ffsecurity.change_dossier')"
            name="edit-btn"
            type="primary"
            @click="() => (edit = true)"
            >{{ $tf('common.edit') }}</el-button
          >

          <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog slot="content" :title="$tf('add_to_monitoring')" :visible.sync="isSenderVisible">
      <puppeteer-sender v-if="isSenderVisible" type="monitoring" :dossierIDs="[item.id]" @sent="addToMonitoringHandler" />
    </el-dialog>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import MetaField from '@/components/dossiers/metafield';
import qs from 'qs';
import ConfirmButton from '../confirm/confirm-button';
import DossierListOption from '../dossier-lists/option.vue';
import ObjectsInline from '../objects/inline';
import PageDailyEvents from '../puppeteer/daily-events/index';
import PuppeteerSender from '../puppeteer/sender';
import DossierAttachments from './attachments/index';
import DossierMonitoringItem from './monitoring.item';
import HeaderBackButton from '@/components/common/header.back.button';
import PageLayout from '@/components/page/layout';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const baseRules = {
  name: [{ required: true, message: 'error.required.field', trigger: 'change' }],
  dossier_lists: [{ required: true, type: 'array', min: 1, message: 'error.required.field', trigger: 'change' }]
};

const PuppeteerTabs = [{ name: 'puppeteer_daily', label: 'Местонахождение' }];

export default {
  components: {
    PageLayout,
    DossierMonitoringItem,
    PageDailyEvents,
    DossierAttachments,
    MetaField,
    DossierListOption,
    ObjectsInline,
    PuppeteerSender,
    ConfirmButton,
    HeaderBackButton
  },
  name: 'dossier-form',
  data() {
    return {
      tab: 'general',
      rules: this.$applyRuleMessages(baseRules),
      item: _.cloneDeep(this.$store.state.dossiers.item.empty),
      attachments: [],
      loading: true,
      edit: false,
      isSenderVisible: false
    };
  },
  computed: {
    carsEnabled() {
      return this.enabledObjects.includes('cars');
    },
    enabledObjects() {
      return this.$store.getters.enabledObjects;
    },
    create() {
      return !this.$route.params.id;
    },
    state() {
      return this.$store.state.dossiers;
    },
    generalName() {
      return (this.metaTabs && this.metaTabs.length && this.metaTabs[0].name) || 'general';
    },
    puppeteerDailyTab() {
      return this.tab === 'puppeteer_daily';
    },
    generalTab() {
      return this.tab === this.generalName;
    },
    metaTabs() {
      const r = []
        .concat(this.$store.state.config.dossier_meta && this.$store.state.config.dossier_meta.tabs, this.$store.getters.puppeteer && PuppeteerTabs)
        .filter((v) => !!v);
      if (r.length && !r.find((v) => v.name === 'general')) r.unshift({ name: 'general', label: 'Общее' });
      return r.length ? r : null;
    },
    hasMetaFields() {
      return !!this.$store.state.config.dossier_meta;
    },
    metaFields() {
      let dossierConfig = this.$store.state.config.dossier_meta;
      return ((dossierConfig && dossierConfig.items) || []).filter((v) => v.display.indexOf('form') > -1);
    },
    displayedMetaFields() {
      return this.metaFields.filter((v) => (this.metaTabs && v.tab ? v.tab === this.tab : this.generalTab));
    }
  },
  created() {
    this.loadInitialFormState();
  },
  methods: {
    async loadInitialFormState() {
      try {
        this.loading = true;
        this.edit = this.create;
        this.tab = this.generalName;
        if (this.$route.params.id) {
          this.item = await this.fetchDossierById(this.$route.params.id);
        }
        if (this.hasMetaFields && !this.$store.state.dicts.loaded_date) {
          await this.$store.dispatch(this.$store.state.dicts.Action.Get);
        }
        await this.$store.dispatch(this.$store.state.dossier_lists.Action.Get);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.loading = false;
      }
    },
    fetchDossierById(id) {
      return this.$store.dispatch(this.state.Action.Get, { id: decodeURIComponent(id) });
    },
    addDossierList() {
      const route = this.$router.resolve({ path: '/dossier-lists/create/' });
      window.open(route.href, '_blank');
    },
    loadDossierLists() {
      this.$store.dispatch(this.$store.state.dossier_lists.Action.Get);
    },
    getSearchPath(filter) {
      const rf = Object.assign(
          {},
          this.$store.state.search.filter.empty,
          {
            sourceDossierId: this.item.id,
            sourceType: 'dossier'
          },
          filter
        ),
        r = '#/search/' + qs.stringify(rf, { sort: alphabeticalSort });
      return r;
    },
    getFilterPath(filter) {
      return '#/events/faces/filter/' + qs.stringify(Object.assign(filter, { limit: 20 }), { sort: alphabeticalSort });
    },
    afterCreate() {
      this.$router.push({ path: '/dossiers/' + this.item.id + '/' });
    },
    previewFileHandler() {},
    deleteFileHandler() {},
    saveHandler() {
      this.$refs.form.validate(this.validateHandler);
    },
    loadObjectHandler(value, index = 0) {
      // nothing to do
    },
    loadAttachments(v) {
      this.attachments = v;
    },
    validateHandler(valid) {
      if (!valid) return;
      let action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.$store
        .dispatch(action, this.item)
        .then(({ id }) => {
          this.$notify({
            type: 'success',
            message: this.$tf(['common.dossier', this.create ? 'common.was_created,, 1' : 'common.was_updated,, 1'])
          });
          return this.create && this.navigateToNewlyCreatedDossierPage(id);
        })
        .then(this.loadInitialFormState)
        .catch(this.handleError);
    },
    navigateToNewlyCreatedDossierPage(id) {
      return new Promise((resolve, reject) => this.$router.push(`/dossiers/${id}/`, resolve, reject));
    },
    deleteHandler() {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then(() => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: '/dossiers/filter/limit=10' });
        })
        .catch(this.handleError);
    },
    cancelHandler() {
      this.$router.backTo({ path: '/dossiers/filter/limit=' + this.state.filter.current.limit });
    },
    async removeFromMonitoring() {
      await this.$store.dispatch('removeFromMonitoring', { dossierIDs: [this.item.id], vueContext: this });
      this.$refs.dossierMonitoring && this.$refs.dossierMonitoring.load();
    },
    externalSearch() {
      this.$store.state.dialog.puppeteer_search.case_face = null;
      this.$store.state.dialog.puppeteer_search.dossier = this.item.id;
      this.$store.state.dialog.puppeteer_search.item = null;
      this.$store.state.dialog.puppeteer_search.enabled = true;
    },
    addToMonitoringHandler() {
      this.isSenderVisible = false;
      setTimeout(() => {
        this.$refs.dossierMonitoring && this.$refs.dossierMonitoring.load();
      }, 1000);
    },
    handleError(error) {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: error } }) });
    }
  },
  watch: {
    $route(to, from) {
      this.loadInitialFormState();
    }
  }
};
</script>
